<template>
    <div>
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                </a>
                <h2>아이디 찾기</h2>
                <div></div>
            </div>
        </div>
        <section class="login_main find_pw">
            <div class="login_main_box">
                <h1>아이디 찾기</h1>
            </div>
            <p class="sub_text">회원님의 이름/전화번호로 가입된 아이디는 다음과 같습니다.</p>
            <div class="login_main_box">
                <div>
                    <ul>
                        <li class="email_box" v-for="(item, i) in mailList" :key="i">
                            <img class="come_from_img" v-if="item.comeFrom !== 'apple'"
                                 :src="'/media/img/login/icon_social_' + item.comeFrom + '.svg'">
                            <img class="apple_icon come_from_img" v-if="item.comeFrom === 'apple'"
                                 :src="'/media/img/login/icon_social_' + item.comeFrom + '.png'">
                            <p>{{ item.email }}</p>
                        </li>
                    </ul>
                </div>
                <button @click="login">로그인</button>
            </div>
        </section>
    </div>
</template>

<script>
import router from "@/router";

export default {
  props: {
    mailList: Array
  },

  methods: {
    login () {
      router.push({ name: 'Login' });
    },
    clickPre () {
      history.back();
    }
  }
};
</script>

<style scoped>
.login_main_box h1 {
  margin-bottom: 0;
  font-weight: 700;
}

.login_main_box p {
  padding: 0;
  margin-left: 6px;
  text-align: center;
  text-decoration: none;
}

.sub_text {
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-size: 28px;
  font-weight: 400;
  line-height: 52px;
  color: var(--whitefff);
  margin-bottom: 37px;
}

.login_main_box ul {
  padding: 0;
}

.login_main_box .email_box {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: #fff;
  text-align: center;
  line-height: 50px;
  background-color: hsla(0, 0%, 100%, .08);
  border-radius: 8px;
  margin-bottom: 12px;
}

.login_main_box .email_box:last-child {
  margin-bottom: 23px;
}

.come_from_img {
  margin-left: 16px;
}

.apple_icon {
  width: 24px;
  height: 24px;
}

.login_main_box button {
  margin-top: 0;
}

@media screen and (max-width: 500px) {
  .sub_text {
    font-size: 20px;
    line-height: 1.2;
    text-align: left;
    width: 90%;
    margin: 0 auto;
    font-weight: 500;
    margin-bottom: 24px;
  }
}
</style>
